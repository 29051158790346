import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const introData = {
  "dialogue_end_response": "I hope this glimpse into Earth's wonders inspires you to appreciate and protect our planet.",
  "name": "Earth",
  "location": [0, 0],
  "loc_confidence": 100,
  "zoom": 1,
  "type": "Celestial Body",
  "comment": "Earth is a unique and vibrant planet teeming with life and natural wonders.",
  "description": "Earth, our home planet, is the third planet from the Sun and the only known celestial body to harbor life. Its diverse ecosystems, rich biodiversity, and stunning landscapes make it a truly remarkable place.",
  "time": "4.5 billion years (approximate age)",
  "people": [
    {
      "name": "David Attenborough",
      "time": "1926 CE – present",
      "description": "A renowned broadcaster and natural historian, Attenborough has inspired many to appreciate and conserve Earth's natural wonders through his documentaries."
    },
    {
      "name": "Rachel Carson",
      "time": "1907 CE – 1964 CE",
      "description": "An influential environmentalist whose work, such as 'Silent Spring,' raised awareness about the impact of human activity on Earth's ecosystems."
    }
  ],
  "timeline_events": [
    {
      "name": "Formation of Earth",
      "time": "4.5 billion years ago",
      "location": [0, 0],
      "zoom": 1,
      "location_confidence": 100,
      "description": "Earth formed from the solar nebula, and over time, its crust cooled, allowing for the development of its diverse surface features."
    },
    {
      "name": "Origin of Life",
      "time": "3.8 billion years ago",
      "location": [0, 0],
      "zoom": 1,
      "location_confidence": 100,
      "description": "Microbial life began in Earth's primordial oceans, setting the stage for the evolution of complex life forms."
    },
    {
      "name": "Great Oxygenation Event",
      "time": "2.4 billion years ago",
      "location": [0, 0],
      "zoom": 1,
      "location_confidence": 100,
      "description": "Photosynthetic organisms increased oxygen levels in the atmosphere, leading to significant changes in Earth's environment."
    },
    {
      "name": "Modern Human Emergence",
      "time": "300,000 years ago",
      "location": [0, 0],
      "zoom": 1,
      "location_confidence": 100,
      "description": "Homo sapiens evolved, eventually spreading across the globe and developing complex societies."
    }
  ],
  "realated_places": [
    {
      "name": "The Moon",
      "location": [0, 0],
      "zoom": 1,
      "location_confidence": 100,
      "type": "Celestial Body",
      "description": "Earth's only natural satellite, influencing tides and stabilizing Earth's axial tilt."
    },
    {
      "name": "The Sun",
      "location": [0, 0],
      "zoom": 1,
      "location_confidence": 100,
      "type": "Star",
      "description": "The Sun provides the energy necessary for life on Earth, driving weather, climate, and photosynthesis."
    }
  ],
  "cultural_significance_s": [
    {
      "name": "World Heritage Sites",
      "description": "Recognized by UNESCO, these sites are important cultural and natural treasures that represent Earth's diverse heritage."
    },
    {
      "name": "Global Environmental Movements",
      "description": "Initiatives like Earth Day and climate change activism highlight the importance of protecting Earth's environment."
    }
  ],
  "modern_significance": [
    {
      "name": "Climate Change Awareness",
      "description": "As Earth's climate changes, understanding and mitigating its effects has become a global priority."
    },
    {
      "name": "Biodiversity Conservation",
      "description": "Efforts to protect Earth's diverse species and ecosystems are crucial for maintaining ecological balance."
    }
  ],
  "natural_features": [
    {
      "name": "Amazon Rainforest",
      "location": [-60.0, -3.4653],
      "zoom": 4,
      "location_confidence": 90,
      "description": "The largest tropical rainforest, home to diverse flora and fauna, and a crucial carbon sink."
    },
    {
      "name": "Great Barrier Reef",
      "location": [147.7, -18.2871],
      "zoom": 5,
      "location_confidence": 85,
      "description": "The world's largest coral reef system, known for its rich marine biodiversity."
    }
  ],
  "artifacts": [
    {
      "name": "Fossil Records",
      "location": [0, 0],
      "zoom": 1,
      "location_confidence": 100,
      "description": "Fossils provide insights into Earth's history and the evolution of life."
    },
    {
      "name": "Ice Cores",
      "location": [0, 0],
      "zoom": 1,
      "location_confidence": 100,
      "description": "Ice cores contain trapped gases and particles, offering clues about Earth's past climate."
    }
  ],
  "events": [
    {
      "name": "Earth Day",
      "time": "April 22 annually",
      "description": "A global event to raise awareness and appreciation for Earth's environment."
    },
    {
      "name": "International Year of Biodiversity",
      "time": "2010",
      "description": "A year-long celebration to highlight the importance of preserving Earth's biodiversity."
    }
  ]
}

const introData2 = {
    "dialogue_end_response": "This extensive report provides a glimpse into Earth's formation, its diverse ecosystems, and its significance in human culture and scientific exploration. If you have more specific questions about Earth or its features, feel free to ask!",
    "name": "Earth",
    "location": [0, 0],
    "loc_confidence": 100,
    "zoom": 0,
    "type": "Planet",
    "comment": "Earth is a dynamic planet with a rich history and complex systems that support life.",
    "description": "Earth is the third planet from the Sun and the only astronomical object known to harbor life. It has a diverse range of climates and ecosystems, supported by its atmosphere and hydrosphere. Earth's geology and biological processes have evolved over billions of years, creating a planet rich in resources and biodiversity.",
    "time": "4.543 billion years ago – Present",
    "figures": [
      {
        "name": "James Hutton",
        "time": "1726 – 1797",
        "description": "Known as the 'Father of Modern Geology,' Hutton introduced the theory of uniformitarianism, which posits that Earth's features were shaped by continuous processes over long periods of time."
      },
      {
        "name": "Charles Darwin",
        "time": "1809 – 1882",
        "description": "His theory of evolution by natural selection explained the diversity of life on Earth, emphasizing the role of environmental conditions in shaping species."
      },
      {
        "name": "Alfred Wegener",
        "time": "1880 – 1930",
        "description": "Proposed the theory of continental drift, suggesting that continents were once a single landmass that drifted apart over time."
      },
      {
        "name": "Carl Sagan",
        "time": "1934 – 1996",
        "description": "An astronomer and science communicator, Sagan popularized the study of planetary science and advocated for the search for extraterrestrial life."
      },
      {
        "name": "James Lovelock",
        "time": "1919 – 2022",
        "description": "Developed the Gaia hypothesis, proposing that Earth's biological and physical components are closely integrated to form a complex, self-regulating system."
      }
    ],
    "timeline_events": [
      {
        "name": "Formation of Earth",
        "time": "4.543 billion years ago",
        "location": [0, 0],
        "zoom": 0,
        "location_confidence": 100,
        "description": "Earth formed from the solar nebula, a disk-shaped mass of gas and dust left over from the Sun's formation."
      },
      {
        "name": "Formation of the Moon",
        "time": "4.5 billion years ago",
        "location": [0, 0],
        "zoom": 0,
        "location_confidence": 100,
        "description": "A Mars-sized body collided with Earth, resulting in debris that eventually formed the Moon."
      },
      {
        "name": "First Life",
        "time": "3.8 billion years ago",
        "location": [0, 0],
        "zoom": 0,
        "location_confidence": 100,
        "description": "Simple single-celled organisms known as prokaryotes emerged, marking the beginning of life on Earth."
      },
      {
        "name": "Cambrian Explosion",
        "time": "541 million years ago",
        "location": [0, 0],
        "zoom": 0,
        "location_confidence": 100,
        "description": "A rapid diversification of life forms occurred, leading to the emergence of most major animal phyla."
      },
      {
        "name": "Permian-Triassic Extinction",
        "time": "252 million years ago",
        "location": [0, 0],
        "zoom": 0,
        "location_confidence": 100,
        "description": "The largest extinction event in Earth's history, wiping out approximately 96% of marine species and 70% of terrestrial vertebrate species."
      },
      {
        "name": "Cretaceous-Paleogene Extinction",
        "time": "66 million years ago",
        "location": [0, 0],
        "zoom": 0,
        "location_confidence": 100,
        "description": "A mass extinction event caused by an asteroid impact, leading to the extinction of the dinosaurs and paving the way for mammalian dominance."
      },
      {
        "name": "Anthropocene Epoch",
        "time": "20th century – Present",
        "location": [0, 0],
        "zoom": 0,
        "location_confidence": 100,
        "description": "A proposed epoch that highlights the significant impact of human activity on Earth's geology and ecosystems."
      }
    ],
    "cultural_significance": [
      {
        "name": "Mythology and Religion",
        "description": "Earth has been central to many mythologies and religious beliefs, often personified as a deity or mother figure."
      },
      {
        "name": "Art and Literature",
        "description": "The beauty and diversity of Earth's landscapes have inspired countless works of art and literature throughout history."
      },
      {
        "name": "Scientific Exploration",
        "description": "Earth's unique features and processes have been the focus of scientific study, leading to advancements in geology, biology, and environmental sciences."
      },
      {
        "name": "Environmental Movements",
        "description": "The recognition of Earth's finite resources and ecological interdependence has spurred global movements advocating for conservation and sustainability."
      },
      {
        "name": "Space Exploration",
        "description": "Earth is often studied in comparison to other celestial bodies, providing insights into planetary formation and the potential for life elsewhere in the universe."
      }
    ],
    "modern_significance": [
      {
        "name": "Climate Change",
        "description": "Human-induced climate change poses significant challenges to Earth's ecosystems and human societies, necessitating global cooperation and innovation."
      },
      {
        "name": "Biodiversity",
        "description": "Earth's biodiversity is crucial for ecosystem stability and human well-being, with ongoing research highlighting the importance of preserving species and habitats."
      },
      {
        "name": "Resource Management",
        "description": "Sustainable management of Earth's natural resources is essential for ensuring the health and prosperity of future generations."
      },
      {
        "name": "Technological Advancements",
        "description": "Advances in technology have transformed our understanding of Earth, enabling detailed observation and analysis of its systems and challenges."
      },
      {
        "name": "Global Cooperation",
        "description": "Addressing issues like climate change and biodiversity loss requires international collaboration and policy-making."
      }
    ],
    "natural_features": [
      {
        "name": "Amazon Rainforest",
        "location": [-60.0253, -3.4653],
        "zoom": 5,
        "location_confidence": 90,
        "description": "The world's largest tropical rainforest, known for its biodiversity and role in global carbon and water cycles."
      },
      {
        "name": "Great Barrier Reef",
        "location": [147.6992, -18.2871],
        "zoom": 6,
        "location_confidence": 85,
        "description": "The world's largest coral reef system, located off the coast of Australia, known for its rich marine life."
      },
      {
        "name": "Mount Everest",
        "location": [86.9250, 27.9881],
        "zoom": 10,
        "location_confidence": 95,
        "description": "The highest point on Earth, located in the Himalayas, a symbol of human exploration and endurance."
      },
      {
        "name": "Sahara Desert",
        "location": [13.1913, 23.4162],
        "zoom": 5,
        "location_confidence": 90,
        "description": "The largest hot desert in the world, spanning North Africa, known for its extreme conditions and vast sand dunes."
      },
      {
        "name": "Antarctica",
        "location": [0, -90],
        "zoom": 3,
        "location_confidence": 100,
        "description": "The southernmost continent, covered in ice, playing a key role in Earth's climate and ocean systems."
      }
    ],
    "artifacts": [
      {
        "name": "Fossil Records",
        "location": [0, 0],
        "zoom": 0,
        "location_confidence": 100,
        "description": "Fossils provide evidence of past life on Earth, helping scientists understand evolutionary processes and environmental changes."
      },
      {
        "name": "Geological Strata",
        "location": [0, 0],
        "zoom": 0,
        "location_confidence": 100,
        "description": "Layers of rock that record Earth's history, offering insights into its geological and climatic past."
      },
      {
        "name": "Ice Cores",
        "location": [0, 0],
        "zoom": 0,
        "location_confidence": 100,
        "description": "Cylinders of ice drilled from glaciers and ice sheets, containing information about past atmospheres and climates."
      },
      {
        "name": "Meteorites",
        "location": [0, 0],
        "zoom": 0,
        "location_confidence": 100,
        "description": "Rocks from space that have impacted Earth, providing clues about the solar system's formation and history."
      },
      {
        "name": "Human Artifacts",
        "location": [0, 0],
        "zoom": 0,
        "location_confidence": 100,
        "description": "Objects created by humans, reflecting cultural practices and technological advancements throughout history."
      }
    ]
}

const useGlobalStore = create(
  // persist(
  (set, get) => ({
    // ViewState: initial map settings
    viewState: {
      longitude: 0,
      latitude: 0,
      zoom: 0,
      pitch: 0,
      bearing: 0,
    },
    messages: [],
    data: introData2,
    location: { longitude: -122.41669, latitude: 37.7853, zoom: 14 },
    homepage: true,
    isLoading: false,
    setIsLoading: (state) => set({ isLoading: state }),
    setMessages: (messages) => {
      console.log(messages)
      set({ messages: messages })
    },
    addMessage: (message) => set((state) => ({ messages: [...state.messages, message] })),
    setData: (data) => {
      console.log(data)
      set({ data: data })
    },
    setLocation: (newLocation) =>
      set((state) => ({ ...state, location: newLocation })),
    setHomepage: (value) => set({ homepage: value }),
    setViewState: (newViewState) =>
      set((state) => ({ ...state, viewState: newViewState })),

    // Future state variables
    userPreferences: {
      theme: 'light',
    },
    setUserPreferences: (preferences) =>
      set((state) => ({
        ...state,
        userPreferences: { ...state.userPreferences, ...preferences },
      })),

    activeLayer: null,
    setActiveLayer: (layer) =>
      set((state) => ({ ...state, activeLayer: layer })),
  }),
  {
    name: 'global-state-storage', // Key in localStorage
    getStorage: () => localStorage,
  }
  // )
);

export default useGlobalStore;
