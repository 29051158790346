import React, { useEffect, useMemo, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ScrollText, Brain, Globe } from 'lucide-react';
import LocationPanel from './LocationPanel';
import ChatPanel from './ChatPanel';

const TabbedPanel = ({
    data,
    setLocation,
    isOpen,
    setHomepage,
    setIsChatOpen,
    isChatOpen
}) => {
    const [activeTab, setActiveTab] = useState('location');
    // const currentData = useMemo(()=> data, [data] )

    useEffect(() => {
        if (data?.location && data?.zoom) {
            setActiveTab("location")
            setLocation({
                longitude: data.location[1],
                latitude: data.location[0],
                zoom: data.zoom
            })
        }
    }, [data])

    const panelVariants = {
        initial: { x: -450, opacity: 0 },
        animate: {
            x: 0,
            opacity: 1,
            transition: { type: "spring", stiffness: 100, damping: 20 }
        },
        exit: {
            x: -450,
            opacity: 0,
            transition: { duration: 0.3 }
        }
    };

    const TabButton = ({ id, icon: Icon, label }) => (
        <motion.button
            onClick={() => setActiveTab(id)}
            className={`
                flex-1 py-3 px-4 flex items-center justify-center gap-2
                transition-all duration-300 relative
                ${activeTab === id
                    ? 'text-blue-600 bg-white/50'
                    : 'text-slate-600 hover:text-blue-600 hover:bg-blue-50/30'
                }
            `}
            whileHover={{ y: -1 }}
            whileTap={{ y: 1 }}
        >
            <Icon className={`w-5 h-5 transition-transform duration-300 ${activeTab === id ? 'scale-110' : ''}`} />
            <span className="font-medium">{label}</span>

            {activeTab === id && (
                <motion.div
                    className="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-600/80"
                    layoutId="activeTab"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                />
            )}
        </motion.button>
    );

    if (!isOpen) return null;



    return (<AnimatePresence>
        <motion.div
            variants={panelVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            className="overflow-hidden top-0 fixed h-full w-full md:w-[420px] backdrop-blur-md shadow-lg border-r border-blue-100 bg-gradient-to-r from-blue-50/80 via-white-500 to-blue-50 drop-shadow-md"
        >
            {/* Header with Logo */}
            {/* <div
                className="z-10 flex justify-between items-center pl-6 pt-6 pb-4"
                onClick={() => setHomepage(true)}
            >
                <div className="text-3xl font-bold cursor-pointer leading-tight bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-sky-500 to-blue-500 drop-shadow-md transition hover:translate-x-1">
                    Echoplot.io
                </div>
            </div> */}
            <div
                className="p-6 pb-4 text-3xl font-bold cursor-pointer leading-tight bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-sky-500 to-blue-500 drop-shadow-md transition hover:translate-x-1"
                onClick={() => setHomepage(true)}
            >
                Echoplot.io
            </div>




            {/* Tab Navigation */}
            <div className="border border-blue-500/30 flex bg-white/30 grid-cols-4 md:grid-cols-2">
                <TabButton
                    id="chat"
                    icon={Brain}
                    label="AI Assist"
                />
                <TabButton
                    id="location"
                    icon={ScrollText}
                    label="Results"
                />

                {/* <TabButton
                    id="map"
                    icon={Globe}
                    label="Map"
                /> */}
            </div>

            {/* Tab Content */}
            <div className="overflow-y-auto">
                <style jsx global>{`
                        ::-webkit-scrollbar {
                            width: 10px;
                        }
                        ::-webkit-scrollbar-track {
                            background: rgb(240 249 255);
                        }
                        ::-webkit-scrollbar-thumb {
                            background: rgb(59 130 246 / 0.5);
                            border-radius: 5px;
                        }
                        ::-webkit-scrollbar-thumb:hover {
                            background: rgb(59 130 246 / 0.7);
                        }
                        * {
                            scrollbar-width: thin;
                            scrollbar-color: rgb(59 130 246 / 0.5) rgb(240 249 255);
                        }
                    `}</style>

                <AnimatePresence mode="wait">
                    <motion.div
                        key={activeTab}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{
                            duration: 0.3,
                            type: "spring",
                            stiffness: 300,
                            damping: 25
                        }}
                    // className="h-full"
                    >
                        {/* <div className='w-full'>sd</div> */}
                        {activeTab === 'location' &&
                            <LocationPanel
                                data={data}
                                setLocation={setLocation}
                                hideHeader={true}
                            />}

                        {activeTab === "chat" && <ChatPanel
                            isOpen={true}
                            setIsOpen={setIsChatOpen}
                            setActiveTab={(tid)=>setActiveTab(tid)}
                            hideHeader={true}
                        />}

                    </motion.div>
                </AnimatePresence>
            </div>
        </motion.div>
    </AnimatePresence>
    );
};

export default TabbedPanel;