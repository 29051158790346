import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, MapPin, Users, Book, Globe, Mountain, Building, Calendar, Gem } from 'lucide-react';

// Keep the Section component as is
const Section = ({ title, icon: Icon, children, defaultOpen = false }) => {
    const [isOpen, setIsOpen] = useState(defaultOpen);

    const sectionVariants = {
        open: {
            opacity: 1,
            height: "auto",
            transition: {
                when: "beforeChildren",
                staggerChildren: 0.1,
                duration: 0.3
            }
        },
        closed: {
            opacity: 0,
            height: 0,
            transition: {
                when: "afterChildren",
                duration: 0.2
            }
        }
    };

    const contentVariants = {
        open: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.2 }
        },
        closed: {
            opacity: 0,
            y: -10,
            transition: { duration: 0.2 }
        }
    };

    return (
        <div className="border-b border-blue-100 last:border-0 py-4">
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="w-full flex items-center justify-between text-xl font-semibold text-slate-800 hover:text-blue-600 transition-colors"
            >
                <div className="flex items-center gap-2 drop-shadow-md">
                    <Icon className="w-5 h-5 text-blue-500" />
                    {title}
                </div>
                <motion.div
                    initial={false}
                    animate={{ rotate: isOpen ? 180 : 0 }}
                    transition={{ duration: 0.2 }}
                >
                    <ChevronDown className="w-5 h-5" />
                </motion.div>
            </button>
            <AnimatePresence initial={false}>
                {isOpen && (
                    <motion.div
                        variants={sectionVariants}
                        initial="closed"
                        animate="open"
                        exit="closed"
                        className="overflow-hidden"
                    >
                        <motion.div
                            variants={contentVariants}
                            className="pt-4 space-y-3"
                        >
                            {children}
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

const LocationPanel = ({ data, setLocation, hideHeader }) => {
    const itemVariants = {
        initial: { y: 20, opacity: 0 },
        animate: { y: 0, opacity: 1 },
        hover: { x: 5, transition: { duration: 0.2 } }
    };

    const _setLocation = (pos) => {
        if (pos?.longitude != null && pos?.latitude != null && pos?.zoom != null)
            setLocation(pos)
        else
            alert("No location data")

    }

    return (
        <div className="max-h-screen overflow-y-auto">

            <div className="p-5 space-y-6">
                {/* // <div className="flex flex-col">
        //     <div className="flex-1 overflow-y-auto p-4 space-y-4 h-full"> */}
                {/* Main Location Card */}
                {data && <motion.div
                    variants={itemVariants}
                    whileHover="hover"
                    className="p-6 space-y-4 bg-gradient-to-br from-sky-50 to-white rounded-md shadow-sm border border-blue-100 cursor-pointer"
                    onClick={() => _setLocation({
                        longitude: data.location[1],
                        latitude: data.location[0],
                        zoom: data.zoom
                    })}
                >
                    <h2 className="text-2xl font-bold text-slate-800 drop-shadow">{data.name}</h2>
                    <p className="text-slate-600">{data.description}</p>
                    <div className="flex flex-wrap gap-2">
                        <span className="px-3 py-1 bg-sky-100 rounded-md text-sm text-blue-600">
                            {data.type}
                        </span>
                        <span className="px-3 py-1 bg-sky-100 rounded-md text-sm text-blue-600">
                            {data.time}
                        </span>
                    </div>
                    <p className="text-sm text-slate-500 font-mono flex items-center gap-2">
                        <MapPin className="w-4 h-4" />
                        {data?.location[1]}, {data?.location[0]} • {data?.loc_confidence}%
                    </p>
                </motion.div>}

                {/* Timeline Section */}
                {data?.timeline_events?.length > 0 && (
                    <Section title="Timeline" icon={Calendar} defaultOpen>
                        <ol className="relative border-s-2 border-blue-200 space-y-6 ml-2">
                            {data.timeline_events.map((event, index) => (
                                <motion.li
                                    key={index}
                                    variants={itemVariants}
                                    whileHover="hover"
                                    onClick={() => _setLocation({
                                        longitude: event.location[1],
                                        latitude: event.location[0],
                                        zoom: event.zoom
                                    })}
                                    className="ms-4 cursor-pointer"
                                >
                                    <div className="absolute w-3 h-3 bg-blue-500 rounded-full mt-1.5 -start-1.5 border-2 border-white" />
                                    <time className="mb-1 text-sm font-normal text-slate-500">{event.time}</time>
                                    <h3 className="text-md font-semibold text-slate-800">{event.name}</h3>
                                    <p className="mb-1 text-sm text-slate-600">{event.description}</p>
                                    <p className="text-xs text-slate-400 font-mono">
                                        {event.location[1]}, {event.location[0]} • {event.location_confidence}%
                                    </p>
                                </motion.li>
                            ))}
                        </ol>
                    </Section>
                )}

                {/* People Section */}
                {data?.figures?.length > 0 && (
                    <Section title="Key People" icon={Users}>
                        {data.figures.map((person, index) => (
                            <motion.div
                                key={index}
                                variants={itemVariants}
                                className="p-4 bg-white rounded-md shadow-sm border border-blue-50"
                            >
                                <h4 className="font-semibold text-slate-800">{person.name}</h4>
                                <p className="text-sm text-blue-600 my-1">{person.time}</p>
                                <p className="text-sm text-slate-600">{person.description}</p>
                            </motion.div>
                        ))}
                    </Section>
                )}

                {/* Cultural & Modern Significance */}
                {(data?.cultural_significance?.length > 0 || data?.modern_significance?.length > 0) && (
                    <Section title="Cultural Impact" icon={Book}>
                        <div className="space-y-4">
                            {data.cultural_significance?.map((item, index) => (
                                <motion.div
                                    key={index}
                                    variants={itemVariants}
                                    className="p-4 bg-white rounded-md shadow-sm border border-blue-50"
                                >
                                    <h4 className="font-semibold text-slate-800">{item.name}</h4>
                                    <p className="text-sm text-slate-600">{item.description}</p>
                                </motion.div>
                            ))}
                            {data.modern_significance?.map((item, index) => (
                                <motion.div
                                    key={index}
                                    variants={itemVariants}
                                    className="p-4 bg-white rounded-md shadow-sm border border-blue-50"
                                >
                                    <h4 className="font-semibold text-slate-800">{item.name}</h4>
                                    <p className="text-sm text-slate-600">{item.description}</p>
                                </motion.div>
                            ))}
                        </div>
                    </Section>
                )}

                {/* Artifacts */}
                {data?.artifacts?.length > 0 && (
                    <Section title="Artifacts" icon={Gem}>
                        {data.artifacts.map((artifact, index) => (
                            <motion.div
                                key={index}
                                variants={itemVariants}
                                whileHover="hover"
                                onClick={() => _setLocation({
                                    longitude: artifact.location[1],
                                    latitude: artifact.location[0],
                                    zoom: artifact.zoom
                                })}
                                className="p-4 bg-white rounded-md shadow-sm border border-blue-50 cursor-pointer"
                            >
                                <h4 className="font-semibold text-slate-800">{artifact.name}</h4>
                                <p className="text-sm text-slate-600 mb-2">{artifact.description}</p>
                                <p className="text-xs text-slate-400 font-mono flex items-center gap-1">
                                    <MapPin className="w-3 h-3" />
                                    {artifact.location[1]}, {artifact.location[0]} • {artifact.location_confidence}%
                                </p>
                            </motion.div>
                        ))}
                    </Section>
                )}

                {/* Related Places */}
                {data?.realated_places?.length > 0 && (
                    <Section title="Related Places" icon={Globe}>
                        {data.realated_places.map((relation, index) => (
                            <motion.div
                                key={index}
                                variants={itemVariants}
                                whileHover="hover"
                                onClick={() => _setLocation({
                                    longitude: relation.location[1],
                                    latitude: relation.location[0],
                                    zoom: relation.zoom
                                })}
                                className="p-4 bg-white rounded-md shadow-sm border border-blue-50 cursor-pointer"
                            >
                                <h4 className="font-semibold text-slate-800">{relation.name}</h4>
                                <p className="text-sm text-slate-600 mb-2">{relation.description}</p>
                                <div className="flex items-center gap-2">
                                    <span className="text-xs text-blue-600 bg-sky-100 px-2 py-1 rounded-md">
                                        {relation.type}
                                    </span>
                                    <span className="text-xs text-slate-400 font-mono flex items-center gap-1">
                                        <MapPin className="w-3 h-3" />
                                        {relation.location[1]}, {relation.location[0]} • {relation.location_confidence}%
                                    </span>
                                </div>
                            </motion.div>
                        ))}
                    </Section>
                )}

                {/* Natural Features */}
                {data?.natural_features?.length > 0 && (
                    <Section title="Natural Features" icon={Mountain}>
                        {data.natural_features.map((feature, index) => (
                            <motion.div
                                key={index}
                                variants={itemVariants}
                                whileHover="hover"
                                onClick={() => _setLocation({
                                    longitude: feature.location[1],
                                    latitude: feature.location[0],
                                    zoom: feature.zoom
                                })}
                                className="p-4 bg-white rounded-md shadow-sm border border-blue-50 cursor-pointer"
                            >
                                <h4 className="font-semibold text-slate-800">{feature.name}</h4>
                                <p className="text-sm text-slate-600 mb-2">{feature.description}</p>
                                <p className="text-xs text-slate-400 font-mono flex items-center gap-1">
                                    <MapPin className="w-3 h-3" />
                                    {feature.location[1]}, {feature.location[0]} • {feature.location_confidence}%
                                </p>
                            </motion.div>
                        ))}
                    </Section>
                )}

                <div className='h-[40vh] text-center text-stone-800/60 drop-shadow-2xl underline p-6'>All content is generated by AI. Accuracy not guaranteed.</div>
            </div>
        </div>
    );
};

export default LocationPanel;