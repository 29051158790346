// import React from 'react';
// import { useEffect } from 'react';
// import { DeckGL } from '@deck.gl/react';
// import { Map } from 'react-map-gl';
// import useGlobalStore from './states/useGlobalStore';
// import 'mapbox-gl/dist/mapbox-gl.css';

// // Mapbox token (replace with your actual token)
// const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

// const MapGL = ({ layers }) => {
//     const { viewState, setViewState } = useGlobalStore();

//     // useEffect(() => {
//     //     const attributionControl = document.querySelector('.mapboxgl-ctrl-attribution');
//     //     if (attributionControl) {
//     //         attributionControl.style.top = '10px';
//     //         attributionControl.style.left = '10px';
//     //         attributionControl.style.right = 'auto';
//     //         attributionControl.style.bottom = 'auto';
//     //     }
//     // }, []);

//     return (
//     <div className='-z-10 w-full h-full'
//         onContextMenu={(e) => e.preventDefault()}>
//         <DeckGL
//             viewState={viewState}
//             onViewStateChange={({ viewState }) => setViewState(viewState)}
//             controller={true}
//             layers={layers}
//         >
//             <Map
//                 {...viewState} // Pass the current viewState
//                 onMove={(evt) => setViewState(evt.viewState)} // Update on user interaction
//                 mapboxAccessToken={MAPBOX_TOKEN}
//                 mapStyle="mapbox://styles/mapbox/standard"
//                // attributionControl={false} // Ensure this is set
//             //  attributionPosition="top-left"
//             attributionControl={true}
//             attributionPosition="bottom-right" // Optional, overridden by CSS below
//             onContextMenu={(e) => e.preventDefault()} // Disable browser context menu

//             >
//                      {/* <style>
//         {`
//           .mapboxgl-ctrl-attribution {
//             position: absolute !important;
//             top: 10px !important;
//             left: 10px !important;
//             right: auto !important;
//             bottom: auto !important;
//             z-index: 10;
//           }
//         `}
//       </style> */}

//                 {/* <div className="custom-attribution">
//                     Map data © <a href="https://www.mapbox.com/">Mapbox</a>
//                 </div> */}
//             </Map>
//         </DeckGL>
//         </div>
//     );
// };

// export default MapGL;


import React, { useEffect, useState, useRef } from "react";
import { DeckGL } from "deck.gl";
import { Map, useControl } from "react-map-gl";
// import { SimpleMeshLayer } from "@deck.gl/mesh";
import { SimpleMeshLayer } from "@deck.gl/mesh-layers";
import { TextLayer } from "@deck.gl/layers";
// import { CubeGeometry, MeshBasicMaterial } from "three";
import { MeshBasicMaterial } from "three";
import { CubeGeometry, IcoSphereGeometry, SphereGeometry, ConeGeometry } from '@luma.gl/engine'
import useGlobalStore from '../states/useGlobalStore';
import { throttle } from "throttle-debounce";
import { MapboxOverlay } from "@deck.gl/mapbox";


function calculateSizeScale(zoom, baseSize = 200, alpha = 1) {
    return baseSize * Math.pow(zoom, alpha);
}

function DeckGLOverlayInterleaved(props) {
    const overlay = useControl(() => new MapboxOverlay({ ...props, interleaved: true }));
    overlay.setProps(props);
    return null;
}


const MapGL = ({ name, description, relationships }) => {
    const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

    const { viewState, setViewState, location } = useGlobalStore();
    const debounceSetViewState = throttle(10, setViewState, { noLeading: true })
    const mapRef = useRef(null);

    // State for hover information
    const [hoverInfo, setHoverInfo] = useState(null);

    // Cube geometry
    // const cubeGeometry = new CubeGeometry(500, 500, 500);
    // const cubeGeometry = new BoxGeometry(500, 500, 500); // Replace CubeGeometry with BoxGeometry


    // Material for cubes
    const mainCubeMaterial = new MeshBasicMaterial({ color: 0x1e90ff }); // Blue
    const relatedCubeMaterial = new MeshBasicMaterial({ color: 0xff6347 }); // Red

    const icosphereMesh = new IcoSphereGeometry({});
    const coneGeometry = new ConeGeometry({ radius: 0.1, height: 2, cap: true })


    // Function to compute scale based on zoom level
    const getScaleFactor = (zoom, baseScale = 1, minscale = 1) => {
        // const baseScale = 1; // Base scale for the mesh
        const zoomScale = Math.max(Math.pow(2, zoom - 10), minscale); // Minimum scale of 0.1
        return baseScale * zoomScale;
    };


    const zoomLevelChange = 13
    const zoomCloseSize = 100


    // SimpleMeshLayer for the main location
    const mainLocationLayer = new SimpleMeshLayer({
        id: "main-location-layer",
        data: [{ position: [location.latitude, location.longitude, 300], name, description }],
        mesh: viewState.zoom < zoomLevelChange ? coneGeometry : icosphereMesh,
        // sizeScale: 1,
        // sizeScale: calculateSizeScale(location.zoom, 200, -0.001),
        getScale: (d) => {
            if (viewState.zoom < zoomLevelChange) {
                const zoomScale = (22 - location.zoom) * 1000 - viewState.zoom * 650 //getScaleFactor(viewState.zoom, (88 - viewState.zoom*10)*100) // Adjust scale based on zoom
                return [zoomScale, zoomScale, zoomScale];
            }
            return [zoomCloseSize, zoomCloseSize, zoomCloseSize]
        },
        getPosition: (d) => d.position,
        getOrientation: d => [0, 0, -90],
        getColor: d => {
            if (viewState.zoom < zoomLevelChange) {
                return [20, 67, 222, 180]
            }
            return [20, 67, 222, 100]
        },
        // material: mainCubeMaterial,
        pickable: true, // Enable picking for hover and click
        updateTriggers: {
            data: location,
            sizeScale: viewState,
            getScale: viewState,
            mesh: viewState.zoom
        },
        onClick: (info) => {
            if (info.object) {
                // setViewState({
                //     latitude: info.object.position[1],
                //     longitude: info.object.position[0],
                //     // zoom: 15,
                //     pitch: 60,
                //     bearing: 0,
                //     transitionDuration: 1000,
                // });
            }
        },
        onHover: (info) => {
            setHoverInfo(info.object ? { x: info.x, y: info.y, data: info.object } : null);
        },
    });

    // SimpleMeshLayer for the main location
    const mainLocationWireframeLayer = new SimpleMeshLayer({
        id: "main-location-layer",
        data: [{ position: [location.latitude, location.longitude, 300], name, description }],
        mesh: viewState.zoom < zoomLevelChange ? coneGeometry : icosphereMesh,
        // sizeScale: 1,
        // sizeScale: calculateSizeScale(viewState.zoom, 200, 0.8),
        getScale: (d) => {
            if (viewState.zoom < zoomLevelChange) {
                const zoomScale = (22 - location.zoom) * 1000 - viewState.zoom * 650 //getScaleFactor(viewState.zoom, (88 - viewState.zoom*10)*100) // Adjust scale based on zoom
                return [zoomScale, zoomScale, zoomScale];
            }
            return [zoomCloseSize, zoomCloseSize, zoomCloseSize]
        },
        // getScale: (d) => {
        //     const zoomScale = getScaleFactor(viewState.zoom) // Adjust scale based on zoom
        //     return [zoomScale, zoomScale, zoomScale];
        // },
        getOrientation: d => [0, 0, -90],
        getPosition: (d) => d.position,
        // getColor: d => [20, 67, 222, 255],
        getColor: d => {
            if (viewState.zoom < zoomLevelChange) {
                return [20, 67, 222, 180]
            }
            return [20, 67, 222, 100]
        },
        // material: mainCubeMaterial,
        pickable: true, // Enable picking for hover and click
        updateTriggers: {
            data: location,
            sizeScale: viewState,
            getScale: viewState,
            mesh: viewState.zoom
        },
        wireframe: true
    });

    // SimpleMeshLayer for related locations
    const relatedLocationsLayer = new SimpleMeshLayer({
        id: "related-locations-layer",
        data: relationships?.map((rel) => ({
            position: rel.location,
            name: rel.name,
            description: rel.description,
        })),
        mesh: icosphereMesh,
        sizeScale: 0.005,
        getPosition: (d) => d.position,
        material: relatedCubeMaterial,
        pickable: true, // Enable picking for hover and click
        onClick: (info) => {
            if (info.object) {
                setViewState({
                    latitude: info.object.position[1],
                    longitude: info.object.position[0],
                    zoom: 15,
                    pitch: 60,
                    bearing: 0,
                    transitionDuration: 1000,
                });
            }
        },
        onHover: (info) => {
            setHoverInfo(info.object ? { x: info.x, y: info.y, data: info.object } : null);
        },
    });

    // TextLayer for the main location
    const mainLocationTextLayer = new TextLayer({
        id: "main-location-text-layer",
        data: [{ position: [location[0], location[1]], name, description }],
        getPosition: (d) => d.position,
        getText: (d) => `${d.name}\n${d.description}`,
        getColor: [30, 144, 255, 255],
        getSize: 20,
        getTextAnchor: "middle",
        getAlignmentBaseline: "top",
    });

    // TextLayer for related locations
    const relatedLocationsTextLayer = new TextLayer({
        id: "related-locations-text-layer",
        data: relationships?.map((rel) => ({
            position: rel.location,
            name: rel.name,
            description: rel.description,
        })),
        getPosition: (d) => d.position,
        getText: (d) => `${d.name}\n${d.description}`,
        getColor: [255, 99, 71, 255],
        getSize: 15,
        getTextAnchor: "middle",
        getAlignmentBaseline: "top",
    });

    // Fly to location when it changes
    useEffect(() => {
        if (mapRef.current && location) {
            try {
                mapRef.current.flyTo({
                    center: [location.latitude, location.longitude],
                    zoom: location.zoom,
                    essential: true, // Ensures the transition is smooth
                    // duration: 500
                });
            }
            catch (e) {
                alert("Fly to location failed, perhaps invalid coordinates.")
            }
        }
    }, [location]);




    return (
        <div className='-z-10 w-screen h-full fixed top-0'
        // onContextMenu={(e) => e.preventDefault()}
        >
            {/* <DeckGL
                initialViewState={viewState}
                // onViewStateChange={({ viewState }) => setViewState(viewState)} // Sync view state
                controller={true}
                layers={[
                    mainLocationLayer,
                    mainLocationWireframeLayer,
                    // mainLocationTextLayer,
                    // relatedLocationsLayer,
                    // relatedLocationsTextLayer,
                ]}
            > */}
            {/* <Map
                ref={mapRef}
                {...viewState} // Pass the current viewState
                onMove={(evt) => {
                    // if (parseInt(evt.viewState.zoom) !== parseInt(viewState.zoom)) {
                    setViewState(evt.viewState)
                    //     console.log(evt.viewState)
                    // }
                }} // Update on user interaction
                mapboxAccessToken={MAPBOX_TOKEN}
                mapStyle="mapbox://styles/mapbox/standard"
                // attributionControl={false} // Ensure this is set
                //  attributionPosition="top-left"
                attributionControl={true}
                attributionPosition="bottom-right" // Optional, overridden by CSS below
                onContextMenu={(e) => e.preventDefault()} // Disable browser context menu
                projection={'globe'}

                initialViewState={viewState}
                // onViewStateChange={({ viewState }) => setViewState(viewState)} // Sync view state
                controller={true}

            > */}

            <Map
                ref={mapRef}
                {...viewState}
                onMove={(evt) => setViewState(evt.viewState)}
                mapboxAccessToken={MAPBOX_TOKEN}
                mapStyle="mapbox://styles/mapbox/standard"
                attributionControl={true}  // Ensure attribution is enabled
                attributionPosition="bottom-right" // Ensure attribution is placed on the right side
                // onContextMenu={(e) => e.preventDefault()}
                projection="globe"
                initialViewState={viewState}
                controller={true}
            >

                <DeckGLOverlayInterleaved
                    id='dgloverlay'
                    controller={true}
                    layers={[
                        mainLocationLayer,
                        mainLocationWireframeLayer,
                        // mainLocationTextLayer,
                        // relatedLocationsLayer,
                        // relatedLocationsTextLayer,
                    ]}>

                </DeckGLOverlayInterleaved>
            </Map>
            {/* </DeckGL> */}

            {/* Tooltip for hover */}
            {/* {hoverInfo && (
                <div
                    className="absolute bg-white p-2 rounded shadow text-sm"
                    style={{ left: hoverInfo.x, top: hoverInfo.y }}
                >
                    <strong>{hoverInfo.data.name}</strong>
                    <p>{hoverInfo.data.description}</p>
                </div>
            )} */}
        </div>
    );
};

export default MapGL;
