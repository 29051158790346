import React from 'react';
import { motion } from 'framer-motion';
import { Globe2, Clock, Map, Compass, BookOpen, ChevronRight } from 'lucide-react';
import useGlobalStore from "./states/useGlobalStore";

// Custom Card Components
const Card = ({ children, className = '' }) => (
  <div className={`rounded-md border border-blue-100 bg-white/60 backdrop-blur-sm shadow-lg ${className}`}>
    {children}
  </div>
);

const CardHeader = ({ children, className = '' }) => (
  <div className={`flex flex-col space-y-1.5 p-6 ${className}`}>
    {children}
  </div>
);

const CardTitle = ({ children, className = '' }) => (
  <h3 className={`font-semibold leading-tight text-slate-800 ${className}`}>
    {children}
  </h3>
);

const CardDescription = ({ children, className = '' }) => (
  <p className={`text-blue-600/80 ${className}`}>
    {children}
  </p>
);

const CardContent = ({ children, className = '' }) => (
  <div className={`p-6 pt-0 ${className}`}>
    {children}
  </div>
);

// Animation variants
const fadeIn = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 }
};

const staggerContainer = {
  animate: {
    transition: {
      staggerChildren: 0.2
    }
  }
};

const EchoplotLanding = () => {
  const { setHomepage } = useGlobalStore()
  return (
    <div className="min-h-screen bg-gradient-to-b from-sky-50 to-white">
      {/* Hero Section */}
      <header className="bg-gradient-to-r from-sky-100 via-white to-blue-100 overflow-hidden relative">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_50%,rgba(56,189,248,0.1),transparent_60%),radial-gradient(circle_at_70%_50%,rgba(147,197,253,0.1),transparent_60%)]"></div>
        <div className="container mx-auto px-6 py-12 pb-24 relative z-10">
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="max-w-3xl"
          >
            <h1 className="text-3xl absolute drop-shadow font-bold mb-6 mt-8 opacity-90 leading-tight bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-sky-600 to-blue-500">
              Echoplot.io
            </h1>
            <h1 className="text-6xl drop-shadow font-bold mb-6 pt-24 leading-tight bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-sky-600 to-blue-500">
              Turn Every Place into a Story
            </h1>

            <p className="text-xl mb-8 text-slate-600">
              Step into a world where history comes alive beneath your feet. Echoplot transforms your everyday exploration into an adventure through time.
            </p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-gradient-to-r from-blue-500 to-sky-500 text-white px-8 py-3 rounded-md font-semibold hover:from-blue-600 hover:to-sky-600 transition-all inline-flex items-center gap-2 shadow-lg shadow-blue-200"
              onClick={() => setHomepage(false)}
            >
              Start Exploring <ChevronRight className="w-4 h-4" />
            </motion.button>
          </motion.div>
        </div>
      </header>

      {/* Time Machine Section */}
      <section className="py-20 bg-white relative">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_50%,rgba(186,230,253,0.4),transparent_70%)]"></div>
        <div className="container mx-auto px-6 relative z-10">
          <motion.h2
            {...fadeIn}
            className="text-4xl font-bold text-center mb-16 text-slate-800"
          >
            Your Personal Time Machine
          </motion.h2>
          <motion.div
            variants={staggerContainer}
            initial="initial"
            whileInView="animate"
            viewport={{ once: true }}
            className="grid md:grid-cols-3 gap-8"
          >
            <FeatureCard
              icon={Clock}
              title="Uncover Hidden Tales"
              description="Discover the untold stories of any street corner, landmark, or city through our AI-powered narrative engine."
            />
            <FeatureCard
              icon={Globe2}
              title="Watch History Unfold"
              description="Experience rich, AI-generated narratives that connect past to present in meaningful ways."
            />
            <FeatureCard
              icon={Map}
              title="Journey Through Time"
              description="Experience locations through the eyes of those who shaped them, with personalised historical context."
            />
          </motion.div>
        </div>
      </section>

      {/* Why Choose Section */}
      <section className="py-20 bg-gradient-to-b from-sky-50/50 to-white relative">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_50%,rgba(147,197,253,0.2),transparent_70%)]"></div>
        <div className="container mx-auto px-6 relative z-10">
          <motion.div
            initial="initial"
            whileInView="animate"
            viewport={{ once: true }}
            variants={staggerContainer}
          >
            <motion.h2
              variants={fadeIn}
              className="text-4xl font-bold text-center mb-16 text-slate-800"
            >
              Why Explorers Choose Echoplot
            </motion.h2>
            <div className="grid md:grid-cols-2 gap-12">
              <motion.div variants={fadeIn}>
                <Card className="h-full">
                  <CardHeader>
                    <CardTitle className="text-2xl">See Beyond the Surface</CardTitle>
                    <CardDescription className="text-lg">
                      Every location holds countless stories waiting to be discovered.
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    <p className="text-slate-600">
                      Whether you're standing in a busy metropolis or a quiet village, Echoplot reveals the layers of history that make it extraordinary. Our AI weaves together local legends, historical events, and cultural significance to create a vivid tapestry of place and time.
                    </p>
                  </CardContent>
                </Card>
              </motion.div>
              <motion.div variants={fadeIn}>
                <Card className="h-full">
                  <CardHeader>
                    <CardTitle className="text-2xl">Make History Personal</CardTitle>
                    <CardDescription className="text-lg">
                      Create your own path through time.
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    <ul className="space-y-4">
                      {[
                        { icon: Compass, text: "Follow the footsteps of historical figures" },
                        { icon: Globe2, text: "Discover how global events shaped your local community" },
                        { icon: BookOpen, text: "Explore various cultures around the world" }
                        // { icon: BookOpen, text: "Create your own journey through time, guided by intelligent suggestions" }
                      ].map((item, index) => (
                        <motion.li
                          key={index}
                          className="flex items-start gap-3 p-3 rounded hover:bg-sky-50 transition-colors"
                          whileHover={{ x: 5 }}
                        >
                          <item.icon className="w-6 h-6 text-blue-500 flex-shrink-0" />
                          <span className="text-slate-600">{item.text}</span>
                        </motion.li>
                      ))}
                    </ul>
                  </CardContent>
                </Card>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-20 bg-gradient-to-br from-sky-100 via-white to-blue-100 relative overflow-hidden">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_0%,rgba(56,189,248,0.2),transparent_70%)]"></div>
        <div className="container mx-auto px-6 text-center relative z-10">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h2 className="text-4xl font-bold mb-6 text-slate-800">Begin Your Journey</h2>
            <p className="text-xl mb-8 max-w-2xl mx-auto text-slate-600">
              Every street has a story. Every building holds memories. Every location is waiting to share its secrets.
            </p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-gradient-to-r from-blue-500 to-sky-500 text-white px-8 py-3 rounded-md font-semibold hover:from-blue-600 hover:to-sky-600 transition-all inline-flex items-center gap-2 shadow-lg shadow-blue-200"
              onClick={() => setHomepage(false)}
            >
              Explore Now <ChevronRight className="w-4 h-4" />
            </motion.button>
          </motion.div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-white text-slate-600 py-12 border-t border-blue-100">
        <div className="container mx-auto px-6 text-center">
          {/* <div className="grid md:grid-cols-4 gap-8 mb-8">
            {[
              {
                title: "Echoplot",
                content: "Let Echoplot be your guide to the extraordinary stories hidden in ordinary places. A product of Data Corridor Limited."
              },
              {
                title: "Features",
                links: ["Dynamic Timelines", "AI Storytelling", "Location Discovery", "Historical Insights"]
              },
              {
                title: "Company",
                links: ["About Us", "Contact", "Privacy Policy", "Terms of Service"]
              },
              {
                title: "Connect",
                links: ["Twitter", "Facebook", "Instagram", "LinkedIn"]
              }
            ].map((section, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <h4 className="font-bold text-lg mb-4 text-slate-800">{section.title}</h4>
                {section.content ? (
                  <p className="text-slate-600">{section.content}</p>
                ) : (
                  <ul className="space-y-2">
                    {section.links.map((link, linkIndex) => (
                      <motion.li 
                        key={linkIndex}
                        className="text-slate-600 hover:text-blue-500 transition-colors cursor-pointer"
                        whileHover={{ x: 5 }}
                      >
                        {link}
                      </motion.li>
                    ))}
                  </ul>
                )}
              </motion.div>
            ))}
          </div> */}
          {/* <div className="pt-8 border-t border-blue-100 text-center text-slate-500"> */}
          <p>© {new Date().getFullYear()}    <a href="https://datacorridor.io" target="_blank" rel="noopener noreferrer" className="hover:text-teal-700">
            Data Corridor Limited</a>. Echoplot and all associated content are trademarks of <a href="https://datacorridor.io" target="_blank" rel="noopener noreferrer" className="hover:text-teal-700">
              Data Corridor Limited</a>.</p>
          {/* </div> */}
        </div>
      </footer>
    </div>
  );
};

const FeatureCard = ({ icon: Icon, title, description }) => {
  return (
    <motion.div variants={fadeIn}>
      <Card className="h-full hover:shadow-xl hover:shadow-sky-100 transition-shadow">
        <CardHeader>
          <div className="flex justify-center mb-4">
            <div className="p-3 bg-sky-100 rounded-md">
              <Icon className="w-8 h-8 text-blue-500" />
            </div>
          </div>
          <CardTitle className="text-xl text-center">{title}</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-slate-600 text-center">{description}</p>
        </CardContent>
      </Card>
    </motion.div>
  );
};

export default EchoplotLanding;