import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Send, Bot, User, Loader } from 'lucide-react';
import axios from 'axios';
import useGlobalStore from './states/useGlobalStore';

function extractJSONObjects(str) {
    console.log(str)

    const objects = [];
    let stack = [];
    let startIndex = -1;

    for (let i = 0; i < str.length; i++) {
        const char = str[i];

        if (char === '{') {
            // If this is the first '{', mark the start index
            if (stack.length === 0) {
                startIndex = i;
            }
            stack.push('{');
        } else if (char === '}') {
            stack.pop();
            // If stack is empty, we've found a complete JSON object
            if (stack.length === 0 && startIndex !== -1) {
                const jsonString = str.substring(startIndex, i + 1);
                try {
                    const jsonObject = JSON.parse(jsonString);
                    objects.push(jsonObject);
                } catch (e) {
                    // If parsing fails, you can choose to handle it differently
                    console.warn(`Failed to parse JSON object: ${jsonString}`);
                }
                startIndex = -1;
            }
        }
    }

    console.log(objects)

    return objects;
}

const ChatPanel = ({ isOpen, setIsOpen, setActiveTab, hideHeader = false }) => {
    const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_KEY;

    const reponseFormat = `user may request info about historical figures, event timelines, relationships between entities, etc. your job is to provide a engaging information with correct locations coordinates as array with order [longitude, lattitude]. timeline array must have at least 7 objects if possible (in ascending chronological order), and other arrays at least 5 objects, the more the better as this is extensive for an extensive report. the zooms are deckgl zoom level. make sure all properties and sub-properties are included, especialliy "dialogue_end_response" and location's latitude and longitude and zooms. your response will be in complete json object and it must be valid for parsing using JSON.parse(). your response must follow strictly to this format: {
    "dialogue_end_response": "[this field is important and must included; your response in dialogue with the user after presenting the result if any]",
  "name": "Prophet Muhammad",
  "location": [39.8262, 21.4225],
  "loc_confidence": 100,
  "zoom": 10,
  "type": "Historical Figure",
  "comment": "Comment on this result",
  "description": "Prophet Muhammad, known as the final prophet in Islam, was born in Mecca in 570 CE. He received divine revelations that became the Quran, forming the foundation of Islam. His teachings emphasized monotheism, social justice, and moral conduct, leading to the transformation of Arabian society and the creation of a global religious community.",
  "time": "570 CE – 632 CE",
  "figures": [
    {
      "name": "Khadijah bint Khuwaylid",
      "time": "555 CE – 619 CE",
      "description": "The first wife of Muhammad and his strongest supporter in the early days of Islam. A wealthy merchant, Khadijah provided emotional and financial support, and she was the first person to accept his prophethood."
    },
    {
      "name": "Abu Bakr",
      "time": "573 CE – 634 CE",
      "description": "A close companion and early convert to Islam. Abu Bakr later became the first Caliph, leading the Muslim community after Muhammad's death."
    },
    ...
  ],
  "timeline_events": [
    {
      "name": "Birth",
      "time": "570 CE",
      "location": [39.8262, 21.4225],
      "zoom": 12,
      "location_confidence": 90,
      "description": "Born in Mecca into the Quraysh tribe. Orphaned at a young age, he was cared for by his grandfather and then his uncle, Abu Talib."
    },
    {
      "name": "Marriage to Khadijah",
      "time": "595 CE",
      "location": [39.8262, 21.4225],
      "zoom": 12,
      "location_confidence": 90,
      "description": "Married Khadijah, a successful merchant. This partnership provided stability and allowed Muhammad to reflect deeply on spiritual matters."
    },
    ...
  ],
  "cultural_significance": [
    {
      "name": "The Quran",
      "description": "The holy book of Islam, regarded as the word of God revealed to Muhammad over 23 years."
    },
    {
      "name": "Sunnah and Hadith",
      "description": "Muhammad's sayings and actions, which serve as a guide for Muslims in all aspects of life."
    },
    ...
  ],
  "modern_significance": [
    {
      "name": "Hajj",
      "description": "The annual pilgrimage to Mecca, retracing the steps of Muhammad and other key figures in Islamic history."
    },
    {
      "name": "Global Islam",
      "description": "Islam, founded by Muhammad, is now practiced by over 1.9 billion people worldwide, influencing cultures, laws, and societies."
    },
    ...
  ],
  "natural_features": [
    {
      "name": "Cave of Hira",
      "location": [39.8404, 21.4515],
      "zoom": 14,
      "location_confidence": 85,
      "description": "The location where Muhammad received the first revelation of the Quran."
    },
    {
      "name": "Mount Uhud",
      "location": [39.6082, 24.4802],
      "zoom": 13,
      "location_confidence": 80,
      "description": "A site near Medina where the Battle of Uhud took place, a key event in Muhammad's life."
    },
    ...
  ],
  "artifacts": [
    {
      "name": "Kaaba",
      "location": [39.8262, 21.4225],
      "zoom": 14,
      "location_confidence": 95,
      "description": "A sacred structure in Mecca central to Islamic worship, restored by Muhammad during the conquest of Mecca."
    },
    {
      "name": "Green Dome in Medina",
      "location": [39.6111, 24.5247],
      "zoom": 13,
      "location_confidence": 90,
      "description": "The tomb of Muhammad in the Al-Masjid an-Nabawi mosque, a major pilgrimage site."
    },
    ...
  ],
}`

    // const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    // const [isLoading, setIsLoading] = useState(false);
    const messagesEndRef = useRef(null);

    const { data, setData, messages, setMessages, isLoading, setIsLoading, addMessage } = useGlobalStore()

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     if (!input.trim()) return;

    //     const userMessage = { role: 'user', content: input };
    //     // setMessages(prev => [...prev, userMessage]);
    //     setMessages([...messages, userMessage]);
    //     setInput('');
    //     setIsLoading(true);

    //     try {
    //         const response = await axios.post(
    //             "https://api.openai.com/v1/chat/completions",
    //             {
    //                 model: "gpt-4o",
    //                 messages: [{ role: 'system', content: reponseFormat }, userMessage], // Send conversation history
    //                 temperature: 0.7
    //             },
    //             {
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                     Authorization: `Bearer ${OPENAI_API_KEY}`, // Replace with your API key
    //                 },
    //             }
    //         );

    //         let parsed =  extractJSONObjects(response.data.choices[0].message.content)[0]

    //         const botMessage = {
    //             role: 'assistant',
    //             content: parsed.dialogue_end_response,
    //             parsed: parsed
    //         };

    //         console.log(botMessage)

    //         setMessages([...messages, botMessage]);
    //         setData(botMessage.parsed)

    //     } catch (error) {
    //         console.error("Error fetching response:", error);
    //         setMessages(prev => [...prev, { role: 'assistant', content: 'Error fetching response.' }]);
    //     }

    //     setIsLoading(false);
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!input.trim()) return;

        const userMessage = { role: 'user', content: input };

        // ✅ Use functional update to ensure correct state update
        setMessages([...messages, userMessage]);
        setInput('');
        setIsLoading(true);

        try {
            const response = await axios.post(
                "https://api.openai.com/v1/chat/completions",
                {
                    model: "gpt-4o",
                    messages: [{ role: 'system', content: reponseFormat }, userMessage], // ✅ Ensure full chat history
                    temperature: 0.7
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${OPENAI_API_KEY}`,
                    },
                }
            );

            let parsed = extractJSONObjects(response.data.choices[0].message.content)[0];

            const botMessage = {
                role: 'assistant',
                content: parsed.dialogue_end_response,
                parsed: parsed
            };

            console.log(botMessage);

            // ✅ Ensure correct state update
            setMessages([...messages, botMessage]);

            console.log(botMessage.parsed)

            setData(botMessage.parsed);
        } catch (error) {
            console.error("Error fetching response:", error);
            setMessages((prevMessages) => [...prevMessages, { role: 'assistant', content: 'Error fetching response.' }]);
        }

        setIsLoading(false);
    };


    const MessageBubble = ({ message }) => (
        <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            className={`flex gap-2 ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}
        >
            {message.role === 'assistant' && (
                <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center flex-shrink-0">
                    <Bot className="w-5 h-5 text-blue-600" />
                </div>
            )}
            <div
                className={`
                    cursor-pointer
                    max-w-[80%] p-3 rounded-lg 
                    ${message.role === 'user'
                        ? 'bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-tr-none'
                        : 'bg-white border border-blue-100 rounded-tl-none'
                    }
                `}
                onClick={() => {
                    setData(message.parsed)
                    setTimeout(() => {
                        setActiveTab("location") // doesnt work
                    }, 500);
                }}
            >
                <p className={`text-sm ${message.role === 'user' ? 'text-white' : 'text-slate-600'}`}>
                    {message.content}
                </p>
            </div>
            {message.role === 'user' && (
                <div className="w-8 h-8 rounded-full bg-gradient-to-r from-blue-600 to-blue-500 flex items-center justify-center flex-shrink-0">
                    <User className="w-5 h-5 text-white" />
                </div>
            )}
        </motion.div>
    );

    return (<div className="flex flex-col bg-gradient-to-b from-sky-50/50 to-white">
        <div className="flex-1 overflow-y-auto p-4 space-y-4">

            {messages?.length > 0 && messages.map ? (
                messages.map((message, index) => (
                    <MessageBubble key={index} message={message} />
                ))
            ) : (
                <div className="flex items-center justify-center text-slate-400 text-center p-4">
                    <div className="space-y-3">
                        <Bot className="w-12 h-12 mx-auto text-blue-300" />
                        <p>Ask anything about a location's history, figures, cultures, or significance.</p>
                    </div>
                </div>
            )}

            {/* data is:
                {JSON.stringify(messages)} */}

            {isLoading && (
                <div className="flex gap-2 items-center">
                    <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center">
                        <Bot className="w-5 h-5 text-blue-600" />
                    </div>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="bg-white border border-blue-100 rounded-lg p-3 rounded-bl-none"
                    >
                        <Loader className="w-5 h-5 text-blue-600 animate-spin" />
                    </motion.div>
                </div>
            )}
            <div ref={messagesEndRef} />
        </div>

        <form
            onSubmit={handleSubmit}
            className="p-4 bg-white border-t border-blue-100"
        >
            <div className="flex gap-2">
                <input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder="Ask about figures, locations and events..."
                    className="flex-1 p-3 border border-blue-100 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500/20 bg-white text-sm"
                />
                <button
                    type="submit"
                    disabled={!input.trim() || isLoading}
                    className="p-3 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-md hover:from-blue-500 hover:to-blue-400 disabled:opacity-50 disabled:cursor-not-allowed transition-all shadow-lg shadow-blue-500/20 disabled:shadow-none"
                >
                    <Send className="w-5 h-5" />
                </button>
            </div>
        </form>

        {/* <div className='h-[100vh] text-center text-stone-800/60 drop-shadow-2xl underline p-6'>All content is generated by AI. Bugs may occur.</div> */}
    </div>
    );
};

export default ChatPanel;
